import React, { useState } from 'react';
import axios from 'axios';
import {
  Button,
  Container,
  FileInput,
  Flex,
  LoadingOverlay,
  Select,
  Stack,
  TextInput,
  rem,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { IconFileCv } from "@tabler/icons-react";
import { statesProvince } from "../../constants";
import { API_URL } from "../../services/url";

type Props = {
  onNext?: () => void;
};

const DriverLicenseDetails: React.FC<Props> = ({ onNext }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [licenseNumber, setLicenseNumber] = useState("");
  const [driver_dc_no, setDriverDCNo] = useState("");
  const [expirationDate, setExpirationDate] = useState<Date | null>(null);
  const [issuingState, setIssuingState] = useState<string | null>(null);
  const [wheelchairAccessibility, setWheelchairAccessibility] = useState<string | null>("");
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  console.log("uploading", uploading);          
  

  // New state variables for file URLs
  const [driverCertificationUrl, setDriverCertificationUrl] = useState("");
  const [dcPhotoUrl, setDcPhotoUrl] = useState("");

  const isFormValid =
    licenseNumber && expirationDate && issuingState && wheelchairAccessibility;

  const uploadFile = async (file: File, urlStateUpdater: React.Dispatch<React.SetStateAction<string>>) => {
    const formData = new FormData();
    formData.append('file', file);
    setUploading(true);
    try {
      const response = await axios.post(
        `https://sshlo9uo92.execute-api.ap-south-1.amazonaws.com/default/uploadtos3?fileName=${file.name}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      urlStateUpdater(response.data.url);
      setUploading(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      showNotification({
        title: "Error",
        message: "Failed to upload file. Please try again.",
        color: "red",
      });
      setUploading(false);
    }
  };

  const handleDriverCertificationChange = (file: File | null) => {
    if (file) {
      uploadFile(file, setDriverCertificationUrl);
    }
  };

  const handleDcPhotoChange = (file: File | null) => {
    if (file) {
      uploadFile(file, setDcPhotoUrl);
    }
  };

  const handleSubmit = async () => {
    if (!isFormValid) {
      showNotification({
        title: "Validation Error",
        message: "Please fill in all the required fields.",
        color: "red",
      });
      return;
    }

    setLoading(true);

    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        window.location.href = "/signin";
        return;
      }

      const response = await axios.post(`${API_URL}driver-license/info/`, {
        driver_license_number: licenseNumber,
        license_expiration_date: expirationDate?.toISOString().split("T")[0],
        license_issuing_state: issuingState,
        driving_license_photo: driverCertificationUrl,
        wheelchair_accessibility: wheelchairAccessibility,
        driver: userId,
        driver_dc_photo: dcPhotoUrl,
        driver_dc_no,
      });

      console.log(response.data);
      showNotification({
        title: "Success",
        message: "Driver license details updated successfully.",
        color: "green",
      });

      if (onNext) onNext();
    } catch (error) {
      console.error(error);
      showNotification({
        title: "Error",
        message: "An error occurred while updating driver license details.",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      size={isMobile ? "xs" : "sm"}
      px="lg"
      py={isMobile ? "md" : "xl"}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "self-start",
        textAlign: "left",
      }}
    >
      <LoadingOverlay visible={loading} />
      <h2>Driver License Details</h2>
      <Stack style={{ width: "100%" }}>
        <TextInput
          label="Driver License Number"
          placeholder="Enter your driver license number"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={licenseNumber}
          onChange={(event) => setLicenseNumber(event.currentTarget.value)}
        />

        <TextInput
          label="Driver DC Number"
          placeholder="Enter your driver dc number"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={driver_dc_no}
          onChange={(event) => setDriverDCNo(event.currentTarget.value)}
        />

        <FileInput
          leftSection={
            <IconFileCv
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          }
          mb="sm"
          onChange={handleDcPhotoChange}
          size="md"
          label="Upload DC"
          placeholder="Your DC"
          leftSectionPointerEvents="none"
        />

        <DatePickerInput
          mb="sm"
          size="md"
          required
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={expirationDate}
          minDate={new Date()}
          onChange={setExpirationDate}
          label="License Expiration Date"
        />

        <Select
          label="License Issuing State/Province"
          placeholder="Select the issuing state/province"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          data={statesProvince}
          value={issuingState}
          onChange={(value) => setIssuingState(value)}
        />

        <Select
          label="Wheelchair Accessibility"
          placeholder="Select wheelchair accessibility"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          data={[
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" },
          ]}
          value={wheelchairAccessibility}
          onChange={setWheelchairAccessibility}
        />

        <FileInput
          leftSection={
            <IconFileCv
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          }
          mb="sm"
          onChange={handleDriverCertificationChange}
          size="md"
          label="Upload license"
          placeholder="Your License"
          leftSectionPointerEvents="none"
        />

        <Flex justify={"center"}>
          <Button
            w={"90%"}
            size="md"
            c={uploading ? "black":"fcolor"}
            variant="gradient"
            gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
            onClick={handleSubmit}
            radius={"md"}
            mt={"sm"}
            disabled={!isFormValid || loading || uploading}
            loading={loading}
            loaderProps={{ type: "oval" }}
          >
            {uploading ? "Uploading..." : "Next"}
          </Button>
        </Flex>
      </Stack>
    </Container>
  );
};

export default DriverLicenseDetails;