import { Badge, Box, Flex, Button, Paper, Text, rem } from "@mantine/core"; // replace 'your-ui-library' with the actual library you're using
import {
  IconCalendar,
  IconMapPin,
  IconCar,
  IconWheelchair,
} from "@tabler/icons-react";
import { memo } from "react";
import { formattedDateTime, humenReadableTime } from "../../utils/datetime";
import { JobInfoProps } from "../CreateJob/type";

const style = { width: rem(16), height: rem(16) };

const LocationIcon = <IconMapPin style={style} />;

const CalendarIcon = <IconCalendar style={style} />;

const CarIcon = <IconCar style={style} />;

const WheelChairIcon = <IconWheelchair style={style} color="blue" />;

const MyJobs = memo(
  ({
    job,
    driverId,
    ismyjob,
  }: {
    job: JobInfoProps;
    driverId: string | null;
    ismyjob?: boolean;
  }) => {
    // replace 'your-driver-id' with the actual driver id
    return (
      <Paper
        key={job.id}
        withBorder
        mt={"sm"}
        radius={0}
        pos={"relative"}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          borderColor: "transparent",
          borderLeftWidth: 4,
          borderLeftColor: job.status === "cancelled" ? "red" : "#2B2B2B",
          backgroundColor: "rgba(9, 31, 91, 0.08)",
          borderRadius: "0.5rem",
          width: "100%",
        }}
        onClick={() => (window.location.href = `/job?id=${job.id}`)}
      >
        {job.posted_by !== driverId && (
          <Box pos={"absolute"} top={-12} right={-4}>
            <Badge color="blue" size="sm">
              Invited
            </Badge>
          </Box>
        )}

        {job.assigned_to === driverId && (
          <Box pos={"absolute"} top={-12} right={-4}>
            <Badge color="green" size="sm">
              Assigned
            </Badge>
          </Box>
        )}

        {job.assigned_to !== driverId &&
          job.posted_by === driverId &&
          job.public && (
            <Box pos={"absolute"} top={-12} right={-4}>
              <Badge color="primary" size="sm">
                Public
              </Badge>
            </Box>
          )}

        {/*here reomoved this condition !job.public &&*/}
        {job.posted_by === driverId && job.status === "cancelled" && (
          <Box pos={"absolute"} top={-12} right={-4}>
            <Badge color="red" size="sm">
              Cancelled
            </Badge>
          </Box>
        )}

        {/* date and time info */}
        <Flex
          direction={"row"}
          align={"center"}
          justify={"space-between"}
          gap={"xs"}
        >
          {/* Left Section: Date and Time */}
          <Flex
            direction={"row"}
            align={"center"}
            justify={"start"}
            gap={6}
            w={"50%"}
          >
            {CalendarIcon}
            <Text size="sm" fw={400}>
              {formattedDateTime(job.pickup_at)}
            </Text>
          </Flex>

          {/* Right Section: Booking ID, Distance, Amount */}
          <Flex
            direction={"row"}
            align={"center"}
            justify={"flex-end"}
            gap={"md"}
            w={"50%"}
          >
            <Flex align={"center"} mr={6}>
              <Text size="xs">ID: </Text>
              <Text size="lg" p={0} fw={700}>
                {job.booking_id}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        {/* Pickup and Drop Address */}
        <Flex justify={"space-between"} align={"center"} mt={"sm"}>
          {/* Left Section: Pickup and Drop Address */}
          <Flex direction={"column"}>
            <Flex direction={"row"} align={"center"} gap={6} mb={"md"}>
              {LocationIcon}
              <Text size="sm" fw={700}>
                {`${job.pickupLoc}`}
              </Text>
              <Text size="xs" fw={400}>
                {`(Pickup)`}
              </Text>
            </Flex>

            <Flex direction={"row"} align={"center"} gap={6}>
              {LocationIcon}
              <Text size="sm" fw={700}>
                {`${job.dropLoc}`}
              </Text>
              <Text size="xs" fw={400}>
                {`(Drop)`}
              </Text>
            </Flex>
          </Flex>

          {/* Right Section: Request Button */}

          {job.assigned_to === driverId ? (
            <Button
              radius={"md"}
              size="xs"
              color="gray"
              variant="outline"
              disabled={true}
            >
              Assigned
            </Button>
          ) : job.posted_by === driverId ? (
            <Button
              radius={"md"}
              size="xs"
              color="gray"
              variant="outline"
              disabled={true}
            >
              Post By You
            </Button>
          ) : (
            <Button
              radius={"md"}
              size="xs"
              color="gray"
              variant="outline"
              disabled={true}
            >
              Invite You
            </Button>
          )}
        </Flex>

        {/* Services Required and Posted Time */}
        <Flex
          direction={"row"}
          align={"center"}
          justify={"space-between"}
          gap={"xs"}
          mt={"md"}
        >
          <Flex align={"center"} gap={"xs"}>
            {job.services_required.includes("wheel") ? WheelChairIcon : CarIcon}
            <Text
              size={"sm"}
              fw={600}
              style={{
                color: job.services_required.includes("wheel")
                  ? "blue"
                  : "black",
              }}
            >
              {job.services_required}
            </Text>
          </Flex>

          <Text size="xs" fw={400}>
            {humenReadableTime(job.posted_at)}
          </Text>
        </Flex>
        <Flex
          direction={"row"}
          align={"center"}
          justify={"space-between"}
          gap={"xs"}
          mt={"md"}
        >
          {job.distance !== 0 && (
            <Flex direction={"row"} align={"center"} gap={6}>
              {LocationIcon}
              <Text size="sm" fw={700}>{`${job.distance} Km`}</Text>
            </Flex>
          )}
          <Text size="sm" fw={700}>{`${
            job.amount ? "$ " + job.amount : ""
          }`}</Text>
        </Flex>
      </Paper>
    );
  }
);

export default MyJobs;
