import {
  ActionIcon,
  Autocomplete,
  Button,
  Checkbox,
  Container,
  Flex,
  LoadingOverlay,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
  rem,
} from "@mantine/core";
import { DatePickerInput, TimeInput } from "@mantine/dates";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { IconArrowLeft, IconClock } from "@tabler/icons-react";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { API_URL } from "../../services/url";
import checkUserId from "../../utils/checkuserid";
import { CarSeaterList } from "./constants";
import { JobProps } from "./type";
import CustomTimePicker from "./CustomTimeInput";

const CreateJob = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [pickupMode, setMode] = useState<string | null>("pickdatetime");

  const [pickupDate, setPickupDate] = useState<Date | null>(null);
  const [pickupTime, setPickupTime] = useState<string>("");
  const [pickupLoc, setPickupLoc] = useState<string>("");
  const [pickup_address, setPickupAddress] = useState<string>("");  

  const [dropLoc, setDropLoc] = useState<string>("");

  const [name, setName] = useState("");
  const [contact, setContact] = useState("");

  const [validPhonenumber, setValidPhone] = useState(false);

  const [amount, setAmount] = useState<string>("");
  const [distance, setDistance] = useState("");

  const [services_required, setService] = useState<string | null>("");
  const [additional_notes, setNotes] = useState("");
  const [mptp_no, setMPTPNumber] = useState("");

  const [publicPost, setPublic] = useState(false);

  const [loading, setLoading] = useState(false);

  const [suggestion, setSugestion] = useState()

  const ref = useRef<HTMLInputElement>(null);

  const urlParams = new URLSearchParams(window.location.search);

  const jobId = urlParams.get("jobId");

  useEffect(() => {
    checkUserId();
    getSuggestion()

    if (jobId) {
      axios.get<JobProps>(`${API_URL}job-details/${jobId}`).then((res) => {
        const job = res.data;
        setPickupDate(new Date(job.pickup_at));
        setPickupTime(
          new Date(job.pickup_at).toLocaleTimeString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
          })
        );
        setPickupLoc(job.pickupLoc);
        setPickupAddress(job.pickup_address);
        setDropLoc(job.dropLoc);
        setName(job.name);
        setContact(job.contact);
        setAmount(job.amount.toString());
        setDistance(job.distance.toString());
        setService(job.services_required);
        setNotes(job?.additional_notes ? job.additional_notes : "");
        setMPTPNumber(job?.mptp_no ? job.mptp_no : "");
        setPublic(job.public);
      });
    }
  }, []);

  const getSuggestion = async () => {
    try {
      const driverId = localStorage.getItem("userId");
      const res = await axios.get(`${API_URL}user/${driverId}/addresses/`);
      setSugestion(res.data.addresses)
      
    } catch (error) {
      
    }
  }

  const pickerControl = (
    <ActionIcon
      variant="subtle"
      color="gray"
      onClick={() => ref.current?.showPicker()}
    >
      <IconClock style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
    </ActionIcon>
  );

  const isFormValid =
    pickupDate &&
    pickupTime &&
    pickupLoc &&
    pickup_address &&
    services_required &&
    dropLoc &&
    // amount &&
    // distance &&
    name &&
    contact &&
    !validPhonenumber;

  const handleSubmit = async () => {
    if (!isFormValid) {
      showNotification({
        title: "Validation Error",
        message: "Please fill in all the required fields.",
        color: "red",
      });
      return;
    }

    setLoading(true);

    try {
      const driverId = localStorage.getItem("userId");
      if (driverId === null || driverId === undefined) {
        window.location.href = "/signin";
        return;
      }

      const formData = {
        pickup_at: mergeDateAndTime(
          pickupDate?.toDateString() || "",
          pickupTime
        ),
        name,
        contact,
        pickup_address,
        pickupLoc,
        dropLoc,
        distance: distance !== "" ? parseInt(distance) : 0,
        amount: amount !== "" ? parseInt(amount) : 0,
        services_required,
        additional_notes,
        mptp_no,
        public: publicPost,
        posted_by: driverId,
        is_active: true,
      };
      console.log("🚀 ~ handleSubmit ~ formData:", formData);

      const response = await axios.post(`${API_URL}job-listings/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Handle successful response
      console.log(response.data);
      showNotification({
        title: "Success",
        message: "Job details created successfully.",
        color: "green",
      });
      window.location.href = "/home";
    } catch (error) {
      // Handle error
      console.error(error);
      showNotification({
        title: "Error",
        message: "An error occurred while creating job details. " + error,
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    if (!isFormValid) {
      showNotification({
        title: "Validation Error",
        message: "Please fill in all the required fields.",
        color: "red",
      });
      return;
    }

    setLoading(true);

    try {
      const driverId = localStorage.getItem("userId");
      if (driverId === null || driverId === undefined) {
        window.location.href = "/signin";
        return;
      }

      const formData = {
        pickup_at: mergeDateAndTime(
          pickupDate?.toDateString() || "",
          pickupTime
        ),
        name,
        contact,
        pickup_address,
        pickupLoc,
        dropLoc,
        distance: distance !== "" ? parseInt(distance) : 0,
        amount: amount !== "" ? parseInt(amount) : 0,
        services_required,
        additional_notes,
        mptp_no,
        public: publicPost,
        posted_by: driverId,
        is_active: true,
        status: "pending",
      };
      console.log("🚀 ~ handleSubmit ~ formData:", formData);

      const response = await axios.put(
        `${API_URL}job-details/${jobId}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle successful response
      console.log(response.data);
      showNotification({
        title: "Success",
        message: "Job details updated successfully.",
        color: "green",
      });
      window.location.href = "/home";
    } catch (error) {
      // Handle error
      console.error(error);
      showNotification({
        title: "Error",
        message: "An error occurred while updating job details. " + error,
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  const validatePhoneNumber = (phone: string) => {
    const re = /^(?:\+?61|0)[2-478]\d{8}$/;
    if (re.test(phone)) {
      setValidPhone(false);
    } else {
      setValidPhone(true);
    }
  };

  function convertTo12Hour(time24: string) {
    try {
      // Split the time string into hours and minutes
      const [hours, minutes] = time24.split(":").map(Number);

      // Determine AM or PM
      const ampm = hours >= 12 ? "PM" : "AM";

      // Convert hours from 24-hour format to 12-hour format
      const hours12 = hours % 12 || 12;

      // Format the 12-hour time string
      const time12 = `${hours12}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;

      return time12;
    } catch (error) {
      console.log("🚀 ~ convertTo12Hour ~ error:", error);
    }
  }

  function mergeDateAndTime(date: string, time24: string) {
    if (date === "" && time24) return "";
    // Convert the 24-hour time to 12-hour time
    const time12 = convertTo12Hour(time24);

    // Merge the date and time strings
    const dateTimeString = `${date} ${time12}`;

    // Parse the string into a Date object
    const dateTime = new Date(dateTimeString);

    return dateTime.toISOString();
  }

  function onChangePickupMode(mode: string) {
    if (mode !== pickupMode)
      if (mode === "readynow") {
        setPickupDate(new Date());
        setPickupTime(
          new Date().toLocaleTimeString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
          })
        );
      } else {
        setPickupDate(null);
        setPickupTime("");
      }
    setMode(mode);
  }

  function goBack(): void {
    window.location.href = "/home";
  }

  return (
    <Container
      size={isMobile ? "xs" : "sm"}
      px="lg"
      py={isMobile ? "md" : "xl"}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "self-start",
        textAlign: "left",
      }}
      bg={"bg"}
    >
      <LoadingOverlay visible={loading} />
      <Flex align={"center"} mb={"lg"} gap={"sm"}>
        <IconArrowLeft
          style={{ width: rem(20), height: rem(20) }}
          stroke={1.5}
          onClick={goBack}
        />
        <Title order={2} fw={700}>
          {`${jobId ? "Update" : "Create"} Job`}
        </Title>
      </Flex>
      <Stack style={{ width: "100%" }} gap={"lg"}>
        <Flex align={"center"} gap={"sm"}>
          <Button
            fullWidth
            size="sm"
            color="primary"
            variant={pickupMode === "pickdatetime" ? "gradient" : "outline"}
            gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
            onClick={() => onChangePickupMode("pickdatetime")}
            style={{ height: "44px" }}
          >
            Pick Date & Time
          </Button>
          <Button
            fullWidth
            size="sm"
            color="primary"
            variant={pickupMode === "readynow" ? "gradient" : "outline"}
            gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
            onClick={() => onChangePickupMode("readynow")}
            style={{ height: "44px" }}
          >
            Ready Now
          </Button>
        </Flex>

        {pickupMode === "pickdatetime" && (
          <>
            <DatePickerInput
              label="Pick date"
              placeholder="Pick date"
              required
              clearable
              minDate={new Date()}
              size="sm"
              labelProps={{ style: { alignSelf: "flex-start" } }}
              value={pickupDate}
              onChange={setPickupDate}
            />

            <div style={{ width: '100%', maxWidth: '800px', margin: '0 auto' }}>
              <CustomTimePicker
                value={pickupTime}
                onChange={(value: string) => {
                  setPickupTime(value);
                  mergeDateAndTime(pickupDate?.toDateString() || "", value);
                }}
                label="Pick time"
                required
                size="sm"
              />
            </div>
          </>
        )}

        <TextInput
          label="Customer Name"
          placeholder="Ex: John Doe"
          required
          size="sm"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={name}
          onChange={(event) => setName(event.currentTarget.value)}
        />

        <TextInput
          label="Customer Contact Number"
          placeholder="Eg : +6141 234 5678 or 0412 345 678"
          required
          type="tel"
          error={validPhonenumber ? "Invalid Phone Number" : null}
          size="sm"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={contact}
          onChange={(event) => setContact(event.currentTarget.value)}
          onBlur={() => validatePhoneNumber(contact)}
        />
        <Text color="gray" size="sm">
          Eg : +6141 234 5678 or 0412 345 678
        </Text>

        {/* <TextInput
          label="Pickup Address"
          placeholder="Flat / House No / Floor / Building"
          required
          size="sm"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={pickup_address}
          onChange={(event) => setPickupAddress(event.currentTarget.value)}
        /> */}

        <Autocomplete
        label="Pickup Address"
        placeholder="Flat / House No / Floor / Building"
        data={suggestion}
        required
        size="sm"
        value={pickup_address}
        labelProps={{ style: { alignSelf: "flex-start" } }}
        onChange={(e) => setPickupAddress(e)}
        limit={5}
        />

        <TextInput
          label="Pickup Location"
          placeholder="Suburb"
          required
          size="sm"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={pickupLoc}
          onChange={(event) => setPickupLoc(event.currentTarget.value)}
        />

        <TextInput
          label="Drop Location"
          placeholder="Suburb"
          required
          size="sm"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={dropLoc}
          onChange={(event) => setDropLoc(event.currentTarget.value)}
        />

        <TextInput
          label="Distance"
          placeholder="Ex : 123 KM"
          required
          size="sm"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={distance}
          onChange={(event) => setDistance(event.currentTarget.value)}
        />

        <TextInput
          label="Amount"
          placeholder="Ex : $ 1200"
          required
          type="number"
          size="sm"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={amount}
          onChange={(event) => setAmount(event.currentTarget.value)}
        />

        <Select
          label="Service Required"
          placeholder="Select service"
          required
          size="sm"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          data={CarSeaterList}
          value={services_required}
          onChange={(value) => setService(value)}
        />

        <TextInput
          placeholder="Additional Notes"
          size="sm"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={additional_notes}
          onChange={(event) => setNotes(event.currentTarget.value)}
        />

        <TextInput
          label="MPTP Card Number"
          placeholder="Ex: NUX - 961"
          size="sm"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={mptp_no}
          onChange={(event) => setMPTPNumber(event.currentTarget.value)}
        />

        {/* <Center> */}
        <Checkbox
          checked={publicPost}
          size="sm"
          onChange={(event) => setPublic(event.currentTarget.checked)}
          label="Make this post public"
        />
        {/* </Center> */}

        <Flex justify={"center"}>
          <Button
            w={"90%"}
            size="md"
            radius={"md"}
            variant="gradient"
            gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
            disabled={!isFormValid || loading}
            onClick={() => (jobId ? handleUpdate() : handleSubmit())}
            loading={loading}
            loaderProps={{ type: "oval" }}
          >
            {`${jobId ? "Update" : "Post"} Job`}
          </Button>
        </Flex>
      </Stack>
    </Container>
  );
};

export default CreateJob;
