import {
  Button,
  Container,
  Flex,
  Image,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import axios from "axios";
import { useLayoutEffect, useState } from "react";
import { URL } from "../../services/url";
import checkUserId from "../../utils/checkuserid";

interface SignUpResponse {
  success: boolean;
  response: string;
  user?: number;
}

const Authentication = () => {
  const passwordPattern =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const isMobile = useMediaQuery("(max-width: 600px)");

  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidemail] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [invalidphone, setInvalidPhone] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(false);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    checkUserId();
  }, []);

  const isFormValid =
    email &&
    phoneNumber &&
    password &&
    confirmPassword &&
    !invalidEmail &&
    !invalidphone &&
    !passwordErr &&
    !confirmPasswordErr;

  const handleSignUp = async (e: any) => {
    e.preventDefault();
    if (!isFormValid) {
      setError("Please fill all required fields");
      return;
    }
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setLoading(true);
    setError("");
    try {
      const response = await axios.post<SignUpResponse>(`${URL}signup/`, {
        email,
        phonenumber: phoneNumber,
        password,
      });

      if (response.data.success) {
        const userId = response.data.user;
        // Store the user ID or any other relevant data in local storage or state management solution
        localStorage.setItem("userId", userId?.toString() || "");
        window.location.href = "/personal";
        // window.location.href = "/signin";
      } else {
        setError(response.data.response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Sign up failed", error);
      setError("An error occurred during sign up");
      setLoading(false);
    }
  };

  const onChangePassword = (e: any) => {
    if (passwordPattern.test(password) === false) {
      setError("Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character");
      setPasswordErr(true);
    } else {
      setError("");
      setPasswordErr(false);
    }
  };

  const onChangeConfirmPassword = (e: any) => {
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      setConfirmPasswordErr(true);
    } else {
      setError("");
      setConfirmPasswordErr(false);
    }
  };

  const isInvalidPhonenumber = () => {
    const re = /^^(?:\+?61|0)[2-478]\d{8}$/;
    if (!re.test(phoneNumber)) {
      setInvalidPhone(true);
      setError("Invalid phone number");
    } else {
      setInvalidPhone(false);
      setError("");
    }
  };

  function validateEmail() {
    // Regular expression for email validation
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(email)) {
      setInvalidemail(true);
      setError("Invalid email address");
    } else {
      setInvalidemail(false);
      setError("");
    }
  }

  return (
    <Container
      size={isMobile ? "xs" : "sm"}
      px="lg"
      py={isMobile ? "md" : "xl"}
      style={{
        height: "95vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "left",
      }}
      bg={"bg"}
    >
      <Flex direction={"column"} align={"center"} gap={"xs"}>
        <Image src={"landingimg.png"} />
        <Title order={1} style={{ fontSize: isMobile ? "2rem" : "4rem" }}>
          Kabbie
        </Title>
        <Text size="sm">Ride as one community.</Text>
      </Flex>
      <Flex
        direction="row"
        align="center"
        justify="center"
        style={{ width: "100%" }}
      >
        <Stack style={{ width: "100%" }}>
          <Title order={2} style={{ fontSize: "1.5rem" }}>
            Driver Signup
          </Title>
          <TextInput
            label="Email"
            placeholder="Enter your email"
            type="email"
            required
            size="md"
            mb="sm"
            labelProps={{ style: { alignSelf: "flex-start" } }}
            value={email}
            error={invalidEmail}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={validateEmail}
          />

          <TextInput
            label="Phone Number"
            placeholder="Enter your phone number"
            type="tel"
            required
            size="md"
            mb="sm"
            error={invalidphone}
            labelProps={{ style: { alignSelf: "flex-start" } }}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            onBlur={isInvalidPhonenumber}
          />

          <PasswordInput
            label="Password"
            placeholder="Enter your password"
            required
            mb="sm"
            size="md"
            error={passwordErr}
            labelProps={{ style: { alignSelf: "flex-start" } }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onFocusCapture={(e) => setError("")}
            onBlurCapture={onChangePassword}
          />

          <PasswordInput
            label="Confirm Password"
            placeholder="Enter your password"
            required
            mb="sm"
            error={confirmPasswordErr}
            size="md"
            labelProps={{ style: { alignSelf: "flex-start" } }}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onFocusCapture={(e) => setError("")}
            onBlurCapture={onChangeConfirmPassword}
          />

          {error && (
            <Text color="red" size="sm" mb="md">
              {error}
            </Text>
          )}

          <Flex justify={"center"}>
            <Button
              w={"90%"}
              size="md"
              mt="sm"
              onClick={handleSignUp}
              variant="gradient"
              gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
              c={"fcolor"}
              loading={loading}
              loaderProps={{ type: "oval" }}
              radius={"md"}
            >
              Create Account
            </Button>
          </Flex>

          <Text size="sm" ta={"center"}>
            Already have an account?{" "}
            <Text component="a" href="/signin" inherit>
              Login
            </Text>
          </Text>
        </Stack>
      </Flex>
    </Container>
  );
};

export default Authentication;
